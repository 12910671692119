export function ConcurrencyError(message = null) {
  this.name = 'ConcurrencyError';
  this.message = (
    message
    || 'Service already has multiple active deployments, try again later.'
  );
}

export function MissingActionError(message = null) {
  this.name = 'MissingActionError';
  this.message = (
    message
    || 'There is no action handler for the button that was clicked.'
  );
}

export default {
  ConcurrencyError,
  MissingActionError,
};
