import React from 'react';
import { API, Auth, CognitoUser } from 'aws-amplify';
import {
  ECSClient,
  DescribeServicesCommand,
  DescribeTasksCommand,
  ListTasksCommand,
  RunTaskCommand,
  UpdateServiceCommand,
} from '@aws-sdk/client-ecs';
import {
  EventBridgeClient,
  ListTargetsByRuleCommand,
} from '@aws-sdk/client-eventbridge';
import { STSClient, AssumeRoleCommand } from '@aws-sdk/client-sts';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import GridButton from './GridButton';
import VersionModal from './VersionModal';
import { ConcurrencyError, MissingActionError } from './Errors';

// pre-defined role mappings
// TODO read as environment variables that are configured in amplify console
const environmentTargetRoles = {
  dev: 'arn:aws:iam::745486049731:role/ECSServiceUpdater',
  stage: 'arn:aws:iam::743305474555:role/ECSServiceUpdater',
  production: 'arn:aws:iam::652146240293:role/ECSServiceUpdater',
};

// set up query for listing services/deployments
// pre-generated query from './graphql/queries.js' doesn't have correct fields selected
const listServices = /* GraphQL */ `
query ListServices(
    $filter: ModelServiceFilterInput
    $deploymentLimit: Int = 1
    $limit: Int = 500
) {
    listServices(
        filter: $filter
        limit: $limit
    ) {
        items {
            id
            environment
            name
            type
            deployments(
                limit: $deploymentLimit,
                sortDirection: DESC
            ) {
                items {
                    ecs
                    terraform
                    timestamp
                    vcs
                    version
                }
            }
        }
    }
}
`;

const deploymentIsComplete = async (params) => {
  const {
    client,
    service,
    deploymentId,
  } = params;

  // console.log(`checking for state of deployment ${deploymentId}`);

  const command = new DescribeServicesCommand({
    services: [service],
  });

  const response = await client.send(command);
  // console.log(response);

  const {
    services: [{
      deployments,
    }],
  } = response;

  // console.log(deployments);

  let state;
  deployments.forEach((d) => {
    const {
      id,
      rolloutState,
    } = d;

    // console.log(`checking deployment ${id}: ${rolloutState}`);

    if (id === deploymentId) {
      // console.log('found match');
      state = rolloutState;
    }
  });

  switch (state) {
    case 'IN_PROGRESS':
      return false;
    case 'COMPLETED':
      return true;
    case 'FAILED':
      throw new Error(`deployment ${deploymentId} failed`);
    default:
      throw new Error(`unable to find deployment ${deploymentId}`);
  }
};

const taskIsStillRunning = async (params) => {
  const {
    client,
    cluster,
    taskArn,
  } = params;

  const command = new DescribeTasksCommand({
    cluster,
    tasks: [taskArn],
  });

  const {
    tasks: {
      0: task,
    },
  } = await client.send(command);
  // console.log(task);

  if (task.desiredStatus === 'RUNNING') {
    return true;
  }

  return false;
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class VersionGrid extends React.Component {
  constructor(props) {
    super(props);

    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.onRowClicked = this.onRowClicked.bind(this);
    const buttonActionHandler = this.buttonActionHandler.bind(this);

    this.state = {
      environment: '*',
      service: '*',
      deploymentLimit: 10,
      stsClient: null,
      suppressClicksInColumns: ['buttonAction'],
      columnDefs: [
        {
          headerName: 'Service',
          children: [
            {
              headerName: '',
              field: 'buttonAction',
              cellRenderer: 'buttonRenderer',
              cellRendererParams: {
                buttonAction: buttonActionHandler,
              },
              autoHeight: true,
              minWidth: 75,
              maxWidth: 115,
              suppressFilter: true,
              suppressMenu: true,
              suppressMovable: true,
              suppressNavigable: true,
              suppressResize: true,
              suppressSorting: true,
            },
            {
              field: 'environment',
              filter: true,
              minWidth: 83,
              maxWidth: 128,
              sort: 'asc',
              sortable: true,
            },
            {
              field: 'name',
              filter: true,
              minWidth: 150,
              sort: 'asc',
              sortable: true,
            },
            {
              field: 'type',
              maxWidth: 100,
            },
          ],
        },
        {
          headerName: 'Deployment',
          children: [
            {
              field: 'version',
              minWidth: 75,
              maxWidth: 100,
            },
            {
              field: 'commit',
              minWidth: 105,
              maxWidth: 400,
            },
            {
              field: 'terraformRun',
              maxWidth: 230,
            },
            {
              field: 'timestamp',
              minWidth: 125,
              maxWidth: 195,
              sortable: true,
            },
          ],
        },
        {
          field: 'modalRowData',
          hide: true,
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
      ],
      defaultColDef: { resizable: true },
      frameworkComponents: { buttonRenderer: GridButton },
      rowData: [],
      modalShow: false,
      modalEnvironment: null,
      modalName: null,
      modalRowData: [],
    };
  }

  componentDidMount() {
    this.getRowData();
    this.initStsClient();
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  onRowClicked = () => {
    const {
      suppressClicksInColumns,
    } = this.state;

    const columnId = this.gridApi.getFocusedCell().column.getColId();

    if (suppressClicksInColumns.includes(columnId)) {
      return;
    }

    const selectedRows = this.gridApi.getSelectedRows();

    this.setState({
      modalEnvironment: selectedRows[0].environment,
      modalName: selectedRows[0].name,
      modalRowData: selectedRows[0].modalRowData,
      modalShow: true,
    });
  };

  async getRowData() {
    // set up query variables
    const { deploymentLimit, environment, service } = this.state;
    const inputVariables = { deploymentLimit };
    const filters = [];

    if (environment !== '*') {
      filters.push({
        environment: { eq: environment },
      });
    }

    if (service !== '*') {
      filters.push({
        name: { eq: service },
      });
    }

    if (filters.length === 1) {
      const filter = filters[0];
      inputVariables.filter = filter;
    } else if (filters.length > 1) {
      inputVariables.filter = { and: filters };
    }

    // Set the query limit to an arbitrarily high value
    inputVariables.limit = 500;

    // run query
    const services = await API.graphql({
      query: listServices,
      variables: inputVariables,
    });

    // parse records in to a list of flat dictionaries
    const rowData = [];

    // filter out services that do not have at least 1 deployment
    services.data.listServices.items.filter((s) => {
      if (s.deployments.items.length < 1) {
        return false;
      }
      return true;
    }).map((s) => {
      // action button parameters
      const buttonActionParams = {
        environment: s.environment,
        name: s.name,
        type: s.type,
      };
      switch (s.type) {
        case 'scheduledTask':
          buttonActionParams.initialText = 'Run task';
          break;
        default:
          buttonActionParams.initialText = 'Restart';
          break;
      }

      // generate modal data
      const modalRowData = [];

      s.deployments.items.map((d) => {
        const { commit_id: commit } = JSON.parse(d.vcs);

        let terraformRun = null;
        if (d.terraform != null) {
          ({ run_id: terraformRun } = JSON.parse(d.terraform));
        }

        return (
          modalRowData.push({
            version: d.version,
            commit,
            timestamp: d.timestamp,
            terraformRun,
          })
        );
      });

      let terraformRun = null;
      if (s.deployments.items[0].terraform != null) {
        ({ run_id: terraformRun } = JSON.parse(s.deployments.items[0].terraform));
      }

      let commit = null;
      if (s.deployments.items[0].vcs != null) {
        ({ commit_id: commit } = JSON.parse(s.deployments.items[0].vcs));
      }

      // add completed row
      return (
        rowData.push({
          buttonAction: buttonActionParams,
          environment: s.environment,
          name: s.name,
          type: s.type,
          version: s.deployments.items[0].version,
          commit,
          terraformRun,
          timestamp: s.deployments.items[0].timestamp,
          modalRowData,
        })
      );
    });

    // update class state
    this.setState(() => ({
      rowData,
    }));
  }

  async initStsClient() {
    const credentials = await Auth.currentCredentials();

    const stsClient = new STSClient({
      region: 'us-west-2',
      credentials: Auth.essentialCredentials(credentials),
    });

    // console.log('stsClient initialized');

    this.setState({ stsClient });
  }

  async buttonActionHandler(params) {
    const {
      user: {
        username,
      },
      region,
    } = this.props;

    const { stsClient } = this.state;

    if (!stsClient) {
      throw new Error('stsClient is null, unable to assume target role');
    }

    const {
      value: {
        environment,
        name: service,
        type,
      },
      callbackComplete,
      callbackFailed,
      callbackInProgress,
    } = params;

    try {
      let command = new AssumeRoleCommand({
        RoleArn: environmentTargetRoles[environment],
        RoleSessionName: username,
      });

      const stsResponse = await stsClient.send(command);

      const {
        Credentials: {
          AccessKeyId: accessKeyId,
          SecretAccessKey: secretAccessKey,
          SessionToken: sessionToken,
        },
      } = stsResponse;

      const ecsClient = new ECSClient({
        region,
        credentials: {
          accessKeyId,
          secretAccessKey,
          sessionToken,
        },
      });

      const eventsClient = new EventBridgeClient({
        region,
        credentials: {
          accessKeyId,
          secretAccessKey,
          sessionToken,
        },
      });

      if (type === 'ecs') {
        callbackInProgress({
          buttonText: 'Restarting...',
          message: `${service}-${environment} restarting`,
        });

        command = new DescribeServicesCommand({
          services: [service],
        });

        const describeResponse = await ecsClient.send(command);

        const {
          services: [{
            deployments,
          }],
        } = describeResponse;

        if (deployments.length > 1) {
          throw new ConcurrencyError(`${service}-${environment} already has multiple deployments, wait a few minutes and try again`);
        }

        command = new UpdateServiceCommand({
          service,
          forceNewDeployment: true,
        });

        const updateResponse = await ecsClient.send(command);
        // console.log(updateResponse);

        const {
          service: {
            deployments: [{
              id,
            }],
          },
        } = updateResponse;

        // eslint-disable-next-line no-await-in-loop
        while (!await deploymentIsComplete({
          service,
          client: ecsClient,
          deploymentId: id,
        })
        ) {
          callbackInProgress({
            buttonText: 'Restarting...',
            message: `${service}-${environment} deployment still in progress`,
          });
          await sleep(20000); /* eslint-disable-line no-await-in-loop */
        }

        callbackComplete({
          message: `${service}-${environment} restart complete`,
        });
      } else if (type === 'scheduledTask') {
        callbackInProgress({
          buttonText: 'Executing...',
          message: `${service}-${environment} task launching`,
        });

        command = new ListTargetsByRuleCommand({
          Rule: `cron-run_ecs_task-${service}`,
        });

        const listTargetsResponse = await eventsClient.send(command);

        // eslint-disable-next-line no-console
        console.log(listTargetsResponse);

        const {
          Targets: {
            0: {
              Arn: cluster,
              EcsParameters: {
                LaunchType: launchType,
                NetworkConfiguration: {
                  awsvpcConfiguration: {
                    AssignPublicIp: assignPublicIp,
                    SecurityGroups: securityGroups,
                    Subnets: subnets,
                  },
                },
                PlatformVersion: platformVersion,
                TaskDefinitionArn: taskDefinition,
              },
            },
          },
        } = listTargetsResponse;

        command = new ListTasksCommand({
          cluster,
          desiredStatus: 'RUNNING',
          family: service,
        });

        const { taskArns } = await ecsClient.send(command);
        // console.log(taskArns);

        if (taskArns.length > 0) {
          throw new ConcurrencyError(`Instance of ${service} already running on ${cluster}.`);
        }

        command = new RunTaskCommand({
          cluster,
          launchType,
          platformVersion,
          taskDefinition,
          networkConfiguration: {
            awsvpcConfiguration: {
              assignPublicIp,
              securityGroups,
              subnets,
            },
          },
        });

        const runTaskResponse = await ecsClient.send(command);

        const {
          tasks: {
            0: {
              taskArn,
            },
          },
        } = runTaskResponse;

        // eslint-disable-next-line no-await-in-loop
        while (await taskIsStillRunning({
          client: ecsClient,
          cluster,
          taskArn,
        })
        ) {
          callbackInProgress({
            buttonText: 'Executing...',
            message: `${service}-${environment} task still executing`,
          });
          await sleep(20000); /* eslint-disable-line no-await-in-loop */
        }

        callbackComplete({
          message: `${service}-${environment} task executed successfully`,
        });
      } else {
        throw new MissingActionError(`No action handler for service type: ${type}`);
      }
    } catch (error) {
      callbackFailed(error);
    }
  }

  render() {
    const {
      columnDefs,
      defaultColDef,
      frameworkComponents,
      rowData,
      modalEnvironment,
      modalName,
      modalRowData,
      modalShow,
    } = this.state;

    return (
      <div
        className="ag-theme-alpine"
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <VersionModal
          show={modalShow}
          onHide={() => this.setState({ modalShow: false })}
          environment={modalEnvironment}
          name={modalName}
          rowData={modalRowData}
        />
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          frameworkComponents={frameworkComponents}
          rowSelection="single"
          onGridReady={this.onGridReady}
          onFirstDataRendered={this.onFirstDataRendered}
          onRowClicked={this.onRowClicked}
          rowData={rowData}
        />
      </div>
    );
  }
}

VersionGrid.propTypes = {
  user: PropTypes.instanceOf(CognitoUser).isRequired,
  region: PropTypes.string.isRequired,
};

export default VersionGrid;
