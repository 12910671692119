import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { Auth } from 'aws-amplify';

const signInOkta = () => {
  Auth.federatedSignIn({ provider: 'Okta' });
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.signInOkta = signInOkta.bind(this);
  }

  render() {
    return (
      <>
        <Row>
          <h3>Select a sign in option...</h3>
        </Row>
        <Row>
          <Button onClick={this.signInOkta}>Okta</Button>
        </Row>
      </>
    );
  }
}

export default Login;
