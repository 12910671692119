import React from 'react';
import Button from 'react-bootstrap/Button';
import { PropTypes } from 'prop-types';

class GridButton extends React.Component {
  constructor(props) {
    super(props);

    const {
      enabledEnvironments,
      value: {
        environment,
        name,
        initialText,
      },
    } = this.props;

    this.buttonActionComplete = this.buttonActionComplete.bind(this);
    this.buttonActionFailed = this.buttonActionFailed.bind(this);
    this.buttonActionInProgress = this.buttonActionInProgress.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.buttonId = `buttonAction-${environment}-${name}`;
    this.environmentEnabled = enabledEnvironments.includes(environment);

    this.state = {
      buttonDisabled: false,
      buttonText: initialText,
      buttonVariant: 'info',
    };
  }

  clickHandler() {
    const {
      buttonAction,
      value,
    } = this.props;

    buttonAction({
      value,
      callbackComplete: this.buttonActionComplete,
      callbackFailed: this.buttonActionFailed,
      callbackInProgress: this.buttonActionInProgress,
    });
  }

  buttonActionComplete({ message = null }) {
    const {
      value: {
        initialText,
      },
    } = this.props;

    // eslint-disable-next-line no-console
    if (message) { console.log(message); }

    this.setState({
      buttonDisabled: false,
      buttonText: initialText,
      buttonVariant: 'info',
    });
  }

  buttonActionFailed({
    name: buttonText = 'Failed',
    message = null,
  }) {
    // eslint-disable-next-line no-console
    if (message) { console.log(message); }

    this.setState({
      buttonDisabled: true,
      buttonText,
      buttonVariant: 'danger',
    });
  }

  buttonActionInProgress({
    buttonText = 'Loading...',
    message = null,
  }) {
    // eslint-disable-next-line no-console
    if (message) { console.log(message); }

    this.setState({
      buttonDisabled: true,
      buttonText,
      buttonVariant: 'warning',
    });
  }

  render() {
    const {
      buttonDisabled,
      buttonText,
      buttonVariant,
    } = this.state;

    let button;
    if (this.environmentEnabled) {
      button = (
        <Button
          id={this.buttonId}
          disabled={buttonDisabled}
          onClick={this.clickHandler}
          size="sm"
          type="button"
          variant={buttonVariant}
          block
        >
          {buttonText}
        </Button>
      );
    } else {
      button = <div />;
    }

    return (
      <>
        {button}
      </>
    );
  }
}

GridButton.propTypes = {
  buttonAction: PropTypes.func.isRequired,
  enabledEnvironments: PropTypes.arrayOf(
    PropTypes.oneOf([
      'dev',
      'stage',
      'production',
    ]),
  ),
  value: PropTypes.shape({
    environment: PropTypes.string,
    initialText: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.oneOf([
      'ecs',
      'scheduledTask',
    ]),
  }).isRequired,
};

GridButton.defaultProps = {
  enabledEnvironments: [
    'dev',
    'stage',
    'production',
  ],
};

export default GridButton;
