import React from 'react';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import { Auth, CognitoUser } from 'aws-amplify';
import PropTypes from 'prop-types';

const signOut = () => Auth.signOut();

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.signOut = signOut.bind(this);
  }

  render() {
    const {
      role,
      user,
    } = this.props;

    return (
      <Navbar bg="dark" variant="dark" sticky="top">
        <Navbar.Brand>VersionBoard 3.0</Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          {user
            ? (
              <>
                <Navbar.Text>
                  Signed in as
                  {' '}
                  <b>
                    {user.username}
                  </b>
                  <p>
                    <i>
                      {role}
                    </i>
                  </p>
                </Navbar.Text>
                <Navbar.Text>
                  <Button variant="secondary" onClick={signOut}>Sign Out</Button>
                </Navbar.Text>
              </>
            )
            : (
              <Navbar.Text>
                Please sign in
              </Navbar.Text>
            )}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

Header.propTypes = {
  role: PropTypes.string,
  user: PropTypes.instanceOf(CognitoUser),
};

Header.defaultProps = {
  role: null,
  user: null,
};

export default Header;
