import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { PropTypes } from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const VersionModal = (props) => {
  const columnDefs = [
    {
      field: 'timestamp',
      sortable: true,
      minWidth: 175,
      maxWidth: 200,
    },
    {
      field: 'version',
      minWidth: 100,
      maxWidth: 125,
    },
    {
      field: 'commit',
    },
    {
      field: 'terraformRun',
      minWidth: 200,
    },
  ];

  const defaultColDef = {
    resizable: true,
    // minWidth: 100,
    // maxWidth: 200,
  };

  const {
    environment,
    name,
    rowData,
    show,
    onHide,
  } = props;

  const onGridReady = (params) => {
    const { api } = params;
    api.sizeColumnsToFit();
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="version-modal-title"
      centered
      onHide={onHide}
      className="ag-theme-alpine"
    >
      <Modal.Header closeButton>
        <Modal.Title id="version-modal-title">
          Deployment History
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          height: '375px',
        }}
      >
        <b>
          {name}
          {' - '}
          {environment}
        </b>
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection="single"
          rowHeight={30}
          onGridReady={onGridReady}
          rowData={rowData}
          enableCellTextSelection="true"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

VersionModal.propTypes = {
  environment: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rowData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string.isRequired).isRequired).isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default VersionModal;
