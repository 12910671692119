import React from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { STSClient, GetCallerIdentityCommand } from '@aws-sdk/client-sts';
import Container from 'react-bootstrap/Container';
import awsconfig from './aws-exports';

import { Header, Login, VersionGrid } from './components';

// determine if this is localhost or not
const isLocalHost = Boolean(
  window.location.hostname === 'localhost'
  || window.location.hostname === '[::1]'
  || window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
  ),
);

// set redirect URLs before configuring amplify
if ('oauth' in awsconfig) {
  if (isLocalHost) {
    awsconfig.oauth.redirectSignIn = 'http://localhost:3000/';
    awsconfig.oauth.redirectSignOut = 'http://localhost:3000/';
  } else {
    awsconfig.oauth.redirectSignIn = `https://${window.location.hostname}/`;
    awsconfig.oauth.redirectSignOut = `https://${window.location.hostname}/`;
  }
  // https://github.com/aws-amplify/amplify-cli/issues/1880
  awsconfig.oauth.domain = 'cognito-devops.natfund.tools';
  // aws-exports missing required values, hard-code them for now
  awsconfig.oauth.responseType = 'token';
  awsconfig.oauth.scope = ['email', 'openid', 'profile'];
}

// use exports to configure amplify
Amplify.configure(awsconfig);

// main application
class App extends React.Component {
  constructor() {
    super();

    this.loadUser = this.loadUser.bind(this);

    this.state = {
      user: null,
      role: null,
      region: 'us-west-2',
    };
  }

  componentDidMount() {
    this.loadUser();
  }

  async loadUser() {
    try {
      const { region } = this.state;

      const user = await Auth.currentAuthenticatedUser();

      const credentials = Auth.essentialCredentials(
        await Auth.currentCredentials(),
      );

      const client = new STSClient({ credentials, region });

      const response = await client.send(new GetCallerIdentityCommand());

      const role = response.Arn.split('/')[1];

      this.setState({
        user,
        role,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);

      this.setState({
        user: null,
        role: null,
      });
    }
  }

  render() {
    const {
      user,
      region,
      role,
    } = this.state;

    return (
      <>
        <Header
          user={user}
          role={role}
        />
        <Container
          fluid
          style={{
            height: '100vh',
          }}
          className="d-flex align-items-center flex-column"
        >
          {user
            ? (
              <VersionGrid
                user={user}
                region={region}
              />
            )
            : <Login />}
        </Container>
      </>
    );
  }
}

export default App;
